import React from 'react';
import OrderPayment from '../../../components/OrderPayment';

const Pay = props => {
  return (
    <>
      <OrderPayment code={props.code} />;
    </>
  );
};

export const Head = () => <title>Płatność | Zapłatomat</title>;

export default Pay;
