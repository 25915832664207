import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {Box, Button, CircularProgress, Container, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions';
import BaseComponent from './containers/BaseComponent';

const color = {
  open: 'primary',
  received: 'secondary',
  processing: 'primary',
  completed: 'secondary',
  cancelled: 'secondary',
};

const label = {
  open: 'OCZEKUJE NA PŁATNOŚĆ',
  received: 'ZAPŁACONO',
  processing: 'ZAPŁACONO',
  completed: 'ZAPŁACONO',
  cancelled: 'ANULOWANO',
};

export function mapStateToProps(state) {
  return {
    order: state.getSimpleOrder,
  };
}

const PREFIX = "OrderPayment";
const classes = {
  logo: `${PREFIX}-logo`,
  flexCenter: `${PREFIX}-flexCenter`,
  titleBox: `${PREFIX}-titleBox`,
  title: `${PREFIX}-title`,
  gridItem: `${PREFIX}-gridItem`,
  upper: `${PREFIX}-upper`,
}
const Root = styled(Box)(({theme}) => ({
  [`& .${classes.logo}`]: {
    minWidth: 300,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [`& .${classes.flexCenter}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.titleBox}`]: {
    background: theme.palette.grey[100],
    color: '#000',
    fontWeight: 'bolder',
    padding: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  [`& .${classes.gridItem}`]: {
    borderBottom: '1px solid #e3e3e3',
  },
  [`& .${classes.upper}`]: {
    textTransform: 'uppercase',
  },
}));

class OrderPayment extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.code) {
      this.props.actions.getSimpleOrder(this.props.code);
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order !== this.props.order) {
      let order = this.props.order;
      if (order.data) {
        this.setState({ order: order.data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  content() {
    if (this.state.loading) {
      return (
        <Box p={4} textAlign='center'>
          <CircularProgress color='primary' />
        </Box>
      );
    } else if (this.state.order) {
      return this.order();
    } else {
      return (
        <Box p={4}>
          <Typography className={classes.flexCenter} variant='h3'>
            Nie znaleziono płatności{' '}
            <SentimentVeryDissatisfiedIcon style={{ fontSize: '3rem' }} />
          </Typography>
        </Box>
      );
    }
  }

  order = () => {
    let order = this.state.order;
    return (
      <>
        <Container maxWidth='sm'>
          <Box textAlign='center' className={classes.titleBox}>
            <Typography variant='body1' className={classes.title}>
              {order.toName}
            </Typography>
          </Box>
          <Box textAlign='center' p={1}>
            <Typography variant='body1' className={classes.title}>
              Prosi Cię o zapłatę
            </Typography>
          </Box>
          <Box py={2}>
            <Grid container spacing={2} justifyContent={'space-between'}>
              <Grid item xs={12}>
                <Box textAlign={'right'} className={'amount'}>
                  <b>
                    {order.amount} {order.currency}
                  </b>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                className={`${classes.gridItem} ${classes.upper}`}
              >
                <b>Opis</b>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Box textAlign={'right'}>{order.title}</Box>
              </Grid>
              <Grid
                item
                xs={6}
                className={`${classes.gridItem} ${classes.upper}`}
              >
                <b>Termin</b>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Box textAlign={'right'}>{order.term}</Box>
              </Grid>
              <Grid
                item
                xs={6}
                className={`${classes.gridItem} ${classes.upper}`}
              >
                <b>Status płatności</b>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Box textAlign={'right'}>
                  <Typography color={color[order.status]}>
                    {label[order.status]}
                  </Typography>
                </Box>
              </Grid>
              {order.status === 'open' && (
                <Grid item xs={12}>
                  <Box p={2} textAlign='center'>
                    <Button
                      color='secondary'
                      variant='contained'
                      disableElevation
                      onClick={() => (window.location.href = order.paymentUrl)}
                    >
                      Zapłać bezpiecznie przez FiberPay
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
      </>
    );
  };

  render() {
    return (
      <Root>
        <Container maxWidth={'sm'}>
          <Box py={2} textAlign='center' className={classes.logo}>
            <StaticImage
              src='../images/logo_zaplatomat.svg'
              alt='Zaplatomat'
              placeholder='none'
            />
          </Box>
          <Box py={4}>{this.content()}</Box>
          <Box className={classes.footer} textAlign='justify'>
            <Typography variant='body2'>
              Operatorem <b>Zapłatomatu</b> jest <b>FiberPay sp. z o.o.</b>,
              zarejestrowana przez Komisję Nadzoru Finansowego w rejestrze
              małych instytucji płatniczych.
            </Typography>
            <Typography variant="body2">
              <b>FiberPay</b> jako Mała Instytucja Płatnicza, pośredniczy w
              przekazaniu środków pomiędzy Klientem, a Sprzedawcą. Przetwarzanie
              danych osobowych związanych z komunikacją i na potrzeby
              komunikacji następuje na zasadach wskazanych w Informacji
              dotyczącej przetwarzania danych osobowych.
            </Typography>
            <Typography variant="body2">
              <b>FiberPay Sp. z o.o.</b> Sienna 86/47, 00-815 Warszawa,
              NIP:&nbsp;7010634566, REGON:&nbsp;365899489, KRS:&nbsp;0000647662.
              Wpis do rejestru małych instytucji płatniczych: MIP28/2019
            </Typography>
          </Box>
        </Container>
      </Root>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPayment);
