import { Component } from 'react';

export default class BaseComponent extends Component {

  handleValueChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleValueBlur(e){
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleCheckboxChange(e) {
    const { name, checked } = e.target
    this.setState({
      [name]: checked
    })
  }

  handleFocus(e) {
    e.target.select()
  }

}